/*https://github.com/ant-design/ant-design/issues/23245*/
.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
	visibility: visible !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
	visibility: visible !important;
}

.ant-picker-panels > *:last-child {
	display: none;
}

.ant-picker-panel-container, .ant-picker-footer {
	width: 280px !important;
}

.ant-picker-footer-extra > div {
	flex-wrap: wrap !important;
}

.ant-radio-button-wrapper {
}
.datePicker {
	padding: 10px 10px 10px 10px;
	border-radius: 8px;
	border-width: 2px;
	font-size: 16px !important;
	font-family: "Inter-Medium";
}
.ant-picker-focused {
	border-color: #d7a137 !important;
	box-shadow: 0 0 #0000 !important;
	font-size: 24px !important;
}
.ant-picker-input {
	font-size: 24px !important;
}

.trigger {
	padding: 0 24px;
	font-size: 18px;
	line-height: 64px;
	cursor: pointer;
	transition: color 0.3s;
}

.trigger:hover {
	color: #1890ff;
}

.logo {
	height: 32px;
	margin: 16px;
	background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
	background: #fff;
}
