@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter-Regular";
  src: url("./assets/fonts/NT-Somic/NTSomic-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter-Medium";
  src: url("./assets/fonts/NT-Somic/NTSomic-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter-Semibold";
  src: url("./assets/fonts/NT-Somic/NTSomic-Bold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter-Bold";
  src: url("./assets/fonts/NT-Somic/NTSomic-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter-Extrabold";
  src: url("./assets/fonts/NT-Somic/NTSomic-Bold.woff2") format("woff2");
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}

@layer utilities {
  .mapboxgl-popup {
    max-width: 400px;
    font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
  }
  .slick-dots li.slick-active button:before {
    color: #881798;
    width: 24px;
    height: 24px;
  }
  input[type="datetime-local"]:invalid {
    color: #bdbdbd;
  }
  input[type="checkbox"]:checked {
    background-color: #2b2d31;
  }
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    opacity: 0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #e1e4e8;
    border-radius: 999px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #e1e4e8;
  }

  /* horizontal scrollbar styles */
  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  ::-webkit-scrollbar-track:horizontal {
    background: transparent;
    opacity: 0;
  }

  ::-webkit-scrollbar-thumb:horizontal {
    background: #e1e4e8;
    border-radius: 999px;
  }

  ::-webkit-scrollbar-thumb:horizontal:hover {
    background: #e1e4e8;
  }

  /*Pagination*/
  .pagination__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* margin-top: 16px;
		margin-bottom: 16px; */
  }

  .pagination__container li {
    list-style: none;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pagination__container a {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    padding: 1px 6px;
    font-family: Inter-Regular;
    font-size: 14;
    /*color: ;*/
    width: 100%;
    height: 100%;
    border: 1px solid #f0f1f3;
    cursor: pointer;
  }

  .pagination__container a:hover {
    color: #303036;
    background-color: rgba(225, 228, 232, 0.1);
  }

  .pagination__active a {
    background-color: rgba(234, 179, 8, 0.1);
    font-family: Inter-Medium;
    font-size: 14;
    color: #efa62e;
    border: 1px solid #efa62e;
  }

  .pagination__active__red a {
    background-color: rgba(124, 7, 17, 0.2);
    font-family: Inter-Medium;
    font-size: 14;
    color: #7c0711;
    border: 1px solid #7c0711;
  }

  .break-me {
    list-style: none;
  }

  .pagination__prev {
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style-type: none;
  }

  .pagination__prev a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 8px 0 0 8px;
    text-decoration: none;
  }

  .pagination__next {
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style-type: none;
  }

  .pagination__next a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0 8px 8px 0;
    text-decoration: none;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: "#ffe418";
  }

  .loader-spinner-gradient {
    border-radius: 50%;
    background: conic-gradient(#0000 10%, #828282);
    -webkit-mask: radial-gradient(
      farthest-side,
      #0000 calc(100% - 6px),
      #000 0
    );
    animation: s3 1s infinite linear;
  }
  @keyframes s3 {
    to {
      transform: rotate(1turn);
    }
  }
}

@layer components {
  .error-message {
    @apply font-medium text-14 text-red-600;
  }
  .card-footer-container {
    @apply flex flex-row items-center justify-end border-t-1 border-t-gray-50 bg-gray-30 px-24 py-13;
  }
  .modal-footer-container {
    @apply sticky bottom-0 flex w-full flex-row items-center justify-end rounded-b-8 border-t border-t-gray-100 bg-white px-24 py-13 shadow-lg;
  }
  .table-outer-container {
    @apply mx-16 rounded-16 border-1 border-gray-10 bg-white;
  }
  .table-header-regular {
    @apply bg-white font-medium text-16 text-gray-300 hover:bg-[rgba(215,161,55,0.3)] hover:text-gray-500 hover:transition-all hover:duration-300 hover:ease-in-out;
  }
  .table-row-regular {
    @apply whitespace-nowrap px-[12px] py-4 text-left text-16;
  }
  .table-row-centered {
    @apply flex flex-col items-center justify-center;
  }
  .input-large-background {
    @apply bg-gray-50 p-12 text-16 focus:outline-yellow-300;
  }
  .input-large-border {
    @apply border border-gray-10 p-12 outline-none focus:outline-2 focus:outline-offset-0 focus:outline-yellow-600;
  }
  .input-mini-border {
    @apply rounded-[4px] border border-gray-10 bg-white py-8 pr-8 text-14 placeholder-gray-500 outline-none;
  }
  .search-input-container {
    @apply input-mini-border min-w-[242px] justify-start rounded-8 bg-white;
  }

  .no-decoration {
    text-decoration: none;
  }

  /* button css */
  .button-transition {
    @apply bg-yellow-600 text-white transition ease-in-out hover:border-yellow-600 hover:bg-transparent hover:text-yellow-600 hover:outline hover:outline-2 active:bg-yellow-600 active:text-white;
  }
  .danger-button-transition {
    @apply bg-transparent text-gray-400 hover:bg-transparent hover:text-red-500;
  }
  .danger-button-transition-filled {
    @apply bg-red-600 text-white transition ease-in-out hover:bg-white hover:text-red-600 hover:shadow focus:outline-none;
  }
  .bordered-button-transition {
    @apply border border-gray-10 bg-transparent text-gray-500 transition ease-in-out hover:border-gray-500 hover:bg-gray-30 hover:text-gray-500 focus:outline-none;
  }
  .green-bordered-button {
    @apply border-green-500 bg-green-100 text-green-800 hover:bg-green-600 hover:text-white;
  }
  .red-bordered-button {
    @apply border-red-600 bg-red-100 text-red-600 hover:border-red-600 hover:bg-red-600 hover:text-white;
  }
  .ant-picker-input {
    @apply border-2 border-gray-10 px-16 py-[10px] outline-none focus:border-yellow-600;
  }
}
